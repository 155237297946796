import type { MaybeElement } from '@vueuse/core'

export const useHomeSwitchCookie = () =>
  useCookie('homeSwitch', {
    default: () => false,
  })

export const useModalHeightCSS = () => {
  const modalHeight = useModalHeightState()
  return computed(() => `calc(100dvh - ${modalHeight.value}px)`)
}

export const useModalHeightState = () => useState('modalHeight', () => 106)

export const useComputeModalHeight = (
  sonyBar: Ref<MaybeElement>,
  navBar: Ref<MaybeElement>,
) => {
  const targetIsVisible = useElementVisibility(sonyBar)
  const { height: sonyBarHeight } = useElementSize(sonyBar)
  const { height: navBarHeight } = useElementSize(navBar)

  const realSonyBarHeight = computed(() =>
    targetIsVisible.value ? sonyBarHeight.value : 0,
  )

  const modalHeight = computed(
    () => realSonyBarHeight.value + navBarHeight.value,
  )

  const modalHeightState = useModalHeightState()

  watch(modalHeight, (newModalHeight) => {
    modalHeightState.value = newModalHeight
  })
}
