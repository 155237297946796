<template>
  <VueFinalModal
    :modal-id="modalId"
    class="modal-container"
    :style="{
      height: modalHeightCSS,
    }"
    content-transition="vfm-fade"
    content-class="modal-content"
    overlay-class="modal-overlay"
  >
    <slot name="sidebar" />
    <div class="modal">
      <header
        v-if="displayHeader"
        class="modal__header"
      >
        <p
          v-if="title"
          class="modal__title"
        >
          {{ title }}
        </p>
        <span
          class="modal__close"
          @click="vfm.closeAll()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.615"
            height="15.615"
            viewBox="0 0 15.615 15.615"
          >
            <g
              stroke="#fff"
              stroke-width=".5"
            >
              <path
                d="M2.061.624A1.016 1.016 0 0 0 .624 2.061l12.93 12.93a1.016 1.016 0 1 0 1.437-1.437Z"
              />
              <path
                d="M.624 13.554a1.016 1.016 0 0 0 1.437 1.437l12.93-12.93A1.016 1.016 0 1 0 13.554.624Z"
              />
            </g>
          </svg>
        </span>
      </header>
      <main class="modal__body">
        <p
          v-if="text"
          class="u-mb-3"
        >
          <strong>{{ text }}</strong>
        </p>
        <slot />
      </main>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal, useVfm } from 'vue-final-modal'

defineSlots<{
  default(): unknown
  sidebar(): unknown
}>

const props = defineProps<{
  modalId?: string
  title?: string
  text?: string
}>()

const modalHeightCSS = useModalHeightCSS()
const vfm = useVfm()

const displayHeader = computed(() => {
  return props.modalId !== 'menu'
})
</script>

<style lang="scss">
.modal-container {
  top: unset;
  bottom: 0;
  z-index: 8 !important;

  .modal-overlay {
    background-color: rgb(27 13 13 / 0.6) !important;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto !important;
  background-color: initial !important;
  border-radius: 0;
  box-shadow: none !important;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: end;
  }
}
</style>

<style lang="scss" scoped>
.modal {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 2rem;
  background-color: #fff;

  @media (min-width: 992px) {
    flex: 0 0 61rem;
    width: 61rem;
    height: 100%;
    padding: 4rem 6rem;
    overflow-y: auto;
  }

  @media (min-width: 1200px) {
    padding: 5rem 8rem;
  }

  &__header {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    @media (min-width: 992px) {
      margin-bottom: 4rem;
    }
  }

  &__body {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  &__title {
    font-size: 2.6rem;
    line-height: 1.15;

    @media (min-width: 992px) {
      font-size: 3.2rem;
    }
  }

  &__close {
    display: flex;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
  }
}

:root {
  --modal-width: 61rem;
  --modal-height: 0;
  --modal-top: 7rem;
}
</style>
